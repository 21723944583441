.member {
  text-align: center;
  img {
    margin-bottom: 1rem;
    width: 100%;
  }
  span {
    font-size: 1.25rem;
    font-weight: 800;
  }
}