#contact {
  font-size: 1.25rem;

  a,
  a:hover {
    color: white;
    text-decoration: none;
  }

  a {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    i {
      margin-right: 8px;
    }
  }
}