.eq-bar {
    transform: scale(1, -1) translate(0, -32px);
  }
  
  .eq-bar--1 {
    animation-name: short-eq;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-delay: 0s;
  }
  
  .eq-bar--1 {
    animation-name: short-eq;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-delay: 0s;
  }
  
  .eq-bar--2 {
    animation-name: tall-eq;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-delay: 0.17s;
  }
  
  .eq-bar--3 {
    animation-name: short-eq;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-delay: 0.34s;
  }
  
  @keyframes short-eq {
    0% {
      height: 12px
    }
  
    50% {
      height: 6px
    }
  
    100% {
      height: 12px
    }
  }
  
  @keyframes tall-eq {
    0% {
      height: 22px
    }
  
    50% {
      height: 10px
    }
  
    100% {
      height: 22px
    }
  }