.video-item {
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 1rem;
    font-weight: 800;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
}