// @media (min-aspect-ratio: 16/9) {
//     #videoBG {
//         width: 100%;
//         height: auto;
//     }
// }

// @media (max-aspect-ratio: 16/9) {
//     #videoBG {
//         width: auto;
//         height: 100%;
//     }
// }

#videoBG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -2;

    video {
        display: block;
        min-height: 100%;
        min-width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }
}

#overlay {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    z-index: -1;
}

main {
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}

body {
    font-family: 'Libre Franklin', sans-serif;
}

#content {
    display: flex;
    flex-direction: column;
}

h3 {
    font-weight: 800;
}

@media (min-width: 992px) {
    // #content.height {
    //     height: 60vh;
    //     height: calc(var(--vh, 1vh) * 60);
    // }
    main {
        padding-top: 160px;
    }
}

#controls {
    display: block;
    flex: 1;
    text-align: right;
    font-size: 32px;

    img {
        cursor: pointer;

        &:last-child {
            margin-left: 16px;
        }
    }
}

.wrapper {
    transition: 0.3s linear;
}

.gigstarter-button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
}