#mainnav {
    margin-bottom: 1.5rem;

    a {
        color: rgba(255, 255, 255, 0.7);
    }
    
    a:hover {
        color: white;
        text-decoration: none;
    }
    
    a.active {
        color: white;
        font-weight: 800;
    }

    .nav-item {
        margin-left: 40px;
        // font-size: 2rem;
        font-size: 1.25rem;
        // font-weight: 800;

        &:first-child {
            margin-left: 0;
        }
    }
}

